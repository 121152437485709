import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import BenefitDriver from "../../../V2/Lab/BenefitDriver"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const BenefitDriverStoryblok = ({
  blok,
}: Storyblok.BlokProps<Storyblok.BenefitDriver>) => {
  const tabs = blok.tabs.map((tab) => ({
    leadText: tab.leadText,
    bodyText: tab.bodyText,
    image: tab.image?.filename,
    imageAlt: tab.image?.alt,
    video: linkIsNotNull(tab.video)
      ? getUrlFromStoryblokLink(tab.video as Storyblok.Link)
      : "",
  }))

  return (
    <BenefitDriver
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
      mode={blok.mode}
      superscriptText={blok.superscriptText}
      headingText={blok.headingText}
      headingBodyText={blok.headingBodyText}
      tabs={tabs}
      buttonText={blok.ctaText}
      showDownloadAppButton={blok.showDownloadAppButton}
      buttonUrl={
        !blok.showDownloadAppButton && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : ""
      }
    />
  )
}

export default BenefitDriverStoryblok
