import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import Typography from "../../../elements/V2/Typography"

type Props = {
  active?: boolean
  textColor: Color
  leadText: string
  bodyText: string
  onClick?: () => void
}

export const ACTIVE_TAB_CLASSNAME = "benefit-driver-tab-active"

const BenefitDriverTab = ({
  active,
  textColor,
  bodyText,
  leadText,
  onClick,
}: Props) => {
  return (
    <div className={active ? ACTIVE_TAB_CLASSNAME : ""}>
      <Typography
        color={active ? Color.Blue : textColor}
        font="grotesk"
        weight="medium"
        size={active ? "lead-lg" : "lead-sm"}
        text={leadText}
        className="cursor-pointer transition-all duration-300 ease-in-out hover:text-blue-v2"
        element="h4"
        onClick={onClick}
      />

      <div
        className={classNames(
          "overflow-hidden transition-all duration-300 ease-in-out",
          active ? "max-h-164-v2 opacity-100 delay-300" : "max-h-0 opacity-0"
        )}
      >
        <Typography
          color={textColor}
          font="grotesk"
          weight="book"
          size="body-lg"
          text={bodyText}
          className="mt-8-v2 opacity-70"
          element="p"
        />
      </div>
    </div>
  )
}

export default BenefitDriverTab
